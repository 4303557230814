import helix from './helix'
import Cookies from 'js-cookie';
import Banners from './banners';
import HelixForm from './HelixForm';
import allStartDates from '../../_data/start-dates';
import showCollateral from './collateral';
import 'regenerator-runtime/runtime';

const banners = new Banners();

window.addEventListener('DOMContentLoaded', (e) => {
    // Menu button
    const menuButton = document.querySelector('button.menu');
    if (menuButton) {
        menuButton.addEventListener('click', (e) => {
            document.body.classList.toggle('nav-open')
        });
    }
    
	// Inquiry form initialization
    document.querySelectorAll('.helixform').forEach((form) => {
        let helixForm = new HelixForm();
        helixForm.validation = {
            "first_name": [
                {type: "required"}
            ],
            "last_name": [
                {type: "required"}
            ],
            "email": [
                {type: "required"},
                {type: "email"},
            ],
            "phone": [
                {type: "required"},
                {type: "phone"}
            ],
            "postal_code": [
                {type: "required"},
                {type: "postal_code"}
            ],
			"living_in_us": [
                {type: "required"}
            ],
            "period_name": [
                {type: "required"},
            ],
            "program_code": [
                {type: "required"},
            ],
        }
        helixForm.initialize(form);
    })

    // Referral form initialization
    document.querySelectorAll('.helixformrefer').forEach((form) => {
        let helixFormRefer = new HelixForm();
        helixFormRefer.validation = {
            "first_name": [
                {type: "required"}
            ],
            "last_name": [
                {type: "required"}
            ],
            "email": [
                {type: "required"},
                {type: "email"},
            ],
            "phone": [
                {type: "phone"}
            ],
            "referrer_comments": [
                {type: "required"}
            ],
            "referrer_first": [
                {type: "required"}
            ],
            "referrer_last": [
                {type: "required"}
            ],
        }
        helixFormRefer.initialize(form);
        helixFormRefer.submissionRedirectUrl = '/thank-you-refer/';
    })

    // Look for program code in the querystring
    // (ie. standalone rfi pages)
    if (window.URLSearchParams) {
        let searchParams = new URLSearchParams(window.location.search);
        let programCode = searchParams.get('program')
        if (programCode != null) {
            document.querySelectorAll('[name="program_code"]').forEach((input) => {
                input.value = programCode
            })
        }
    }

    /**
	 * YouTube players
	 */
	const ytApiKey = "AIzaSyAMtPQNVdy9rsvzRPjWvam_XYzherM4Poc";
	// go through each video module
	document.querySelectorAll('div.embed').forEach((video) => {
		// get thumbnail from YouTube if none is pre-defined
		if (typeof video.dataset.frame == "undefined") {
			// get the video id
			let videoId = video.dataset.videoId;
			// query YouTube API for thumbnail
			let req = new XMLHttpRequest();
			req.addEventListener('load', () => {
				let data = JSON.parse(req.responseText);
				let thumbnailUrl = data.items[0].snippet.thumbnails.standard.url;
				video.style.backgroundImage = "url('" + thumbnailUrl + "')";
			})
			req.open('GET', 'https://www.googleapis.com/youtube/v3/videos?id=' + videoId + '&key=' + ytApiKey + '&part=snippet&fields=items(snippet/thumbnails/standard)');
			req.send();
		}
	});	
	document.querySelectorAll('div.embed a').forEach((link) => {
		link.addEventListener('click', (e) => {
			e.preventDefault();
			let parent = link.getParent('.embed');
			parent.innerHTML = parent.innerHTML.replace(/(<!--|-->)/g, '');
		})
	});


    // Get the current page's URL
    var currentPageUrl = window.location.href;

    // Find all form fields with the name "referrer_url" and update their values
    var formFields = document.querySelectorAll('input[name="referrer_url"]');
    formFields.forEach(function(field) {
    field.value = currentPageUrl;
    });

	// Show collateral download if necessary
	showCollateral();

	
    // Global start date replacement 
	if (typeof greaterThan == "undefined") var greaterThan = "01/01/2000"; 
	greaterThan = greaterThan.split('/');
	var greaterThanDate = new Date(greaterThan[2],(greaterThan[0] - 1),greaterThan[1]);
	var months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
	var now = new Date();
	var threshold = 0; // number of days ahead it switches
	for(var i = 0; i < allStartDates.length; i++) {
		var date = allStartDates[i].split('/');
		var newDate = new Date(date[2],(date[0] - 1),date[1]);
		if (newDate.getTime() >= now.getTime() + (threshold*24*60*60*1000) && newDate >= greaterThanDate) {
			var dateReplacements = document.querySelectorAll('.date-replace')
			dateReplacements.forEach(function(dateReplacement){
				dateReplacement.innerHTML = "<strong>" + months[date[0] - 1] + ' ' + date[1] + "</strong>";
			})
			break;
		}
	}

    var activeSlide = document.querySelector('.slide-start');

    function cycle(index){

        // Check if the activeSlide exists
            if (!activeSlide) {
                return;
            }
        
            var nextSlide = activeSlide.nextElementSibling;
        
        setTimeout(function(){

            if ( index == 3 ){
                nextSlide = document.querySelector('.slide-start');}
                else {
                nextSlide = activeSlide.nextElementSibling;}

            activeSlide.classList.add('opacity-0');

            setTimeout(function(){
                activeSlide.classList.add('hidden');
                nextSlide.classList.remove('hidden');
            }, 1000);
        
            setTimeout(function(){
                nextSlide.classList.remove('opacity-0');
            }, 2000);

            setTimeout(function(){
                activeSlide = nextSlide
            }, 4000);
        }, 4000);

        setTimeout(function(){
            index++; // Increment the index
            if (index == 4){
                index = 0;
            }
            cycle(index);
        }, 10000);
    }


    cycle(0);
    

    /* Academic Period field in RFI */

    /* URL we want to fetch - TODO get Brown ID */
    const endpoint = "https://academic-periods-api.prodhelix.com/clients/12015/academic-periods";
    /* Attributes we want to keep (cuz there's a lot) */
    const attrs = ['academic_period_name', 'id_academic_periods', 'start_date', 'is_disabled'];
    var now = new Date();

    let startDateList;
    const fetch = require('node-fetch');

    startDateList = new Promise((resolve, reject) => {
        fetch(endpoint)
            .then(res => res.json())
            .then(json => {
                let data = [];
                json.data.forEach((item) => {
                    if (typeof item.is_disabled != "undefined" && item.is_disabled === true) return;
                    var start = new Date(item.start_date);
                    if (start <= now) return;

                    let itemClone = {};
                    attrs.forEach((attr) => {
                        if (item[attr]) {
                            itemClone[attr] = item[attr];
                        }
                    });

                    data.push(itemClone);
                });

                resolve(data);
            });
    });

    const periodList = (selectedValue, form) => {
        startDateList.then((a) => {
            // Apply additional filters for SCM_BIOS_HDS
            let filteredList = a;
            if (selectedValue === "SCM_BIOS_HDS") {
                const filterDate = new Date('2025-01-22');
                filteredList = a.filter(item => new Date(item.start_date) >= filterDate && !item.academic_period_name.toLowerCase().includes("summer"));
            }

            var sortedList = filteredList.sort((a, b) => {
                const dateA = new Date(a.start_date).getTime();
                const dateB = new Date(b.start_date).getTime();
                return dateA - dateB;
            });

            var periodReplacements = form.querySelectorAll("select[name='period_name']");

            periodReplacements.forEach(function (periodReplacement) {
                // Clear existing options
                periodReplacement.innerHTML = '';

                for (var i = 0; i < sortedList.length; i++) {
                    var option = document.createElement("option");
                    option.text = sortedList[i].academic_period_name;
                    option.value = sortedList[i].academic_period_name;
                    periodReplacement.appendChild(option);
                }
            });
        });
    };

    // Function to set up event listeners for each form
    const setupFormListeners = (form) => {
        const programCodeSelect = form.querySelector("select[name='program_code']");
        if (programCodeSelect) {
            programCodeSelect.addEventListener("change", function (event) {
                const selectedValue = event.target.value;
                if (selectedValue === "MPH_GENERAL" || selectedValue === "SCM_BIOS_HDS") {
                    periodList(selectedValue, form);
                }
            });
        }

        const programCodeHidden = form.querySelector("input[type='hidden'][name='program_code']");
        if (programCodeHidden) {
            const hiddenValue = programCodeHidden.value;
            if (hiddenValue === "MPH_GENERAL" || hiddenValue === "SCM_BIOS_HDS") {
                periodList(hiddenValue, form);
            }
        }
    };

    // Find all forms and set up listeners
    const forms = document.querySelectorAll("form");
    forms.forEach((form) => {
        setupFormListeners(form);
    });

});


Node.prototype.getParent = function(selector) {
    var element = this;
    while (element = element.parentElement) { 
        if (element.matches(selector)) { 
            return element;
            break;
        } 
    }
}