module.exports = [
   {
      "id": "brown-mph-start-date",
      "type": "start-date",
      "default": true,
      "include": [
         "/",
         "/about/",
         "/refer/",
         "/tuition-and-financial-aid/"
      ]
   },
   {
      "id": "brown-mph-spring-start-september-15",
      "type": "start-date-custom",
      "include": [
         "/mph/"
      ],
      "dates": {
         "start": "7/16/2023",
         "end": "9/16/2023",
      },
      "content": {
         "description": "Spring 2024 Application Now Open! Priority Deadline: September 15",
      },
   },
   {
      "id": "brown-mph-2024-fall-start-early",
      "type": "start-date-custom",
      "include": [
         "/mph/"
      ],
      "dates": {
         "start": "2/16/2024",
         "end": "3/16/2024",
      },
      "content": {
         "description": "Fall 2024 Application Now Open! Early Action Deadline: March 15",
      },
   },
   {
      "id": "brown-mph-2024-fall-start-priority",
      "type": "start-date-custom",
      "include": [
         "/mph/"
      ],
      "dates": {
         "start": "3/16/2024",
         "end": "5/2/2024",
      },
      "content": {
         "description": "Fall 2024 Application Now Open! Priority Action Deadline: May 1",
      },
   },
   {
      "id": "brown-mph-2024-fall-start-final",
      "type": "start-date-custom",
      "include": [
         "/mph/"
      ],
      "dates": {
         "start": "5/2/2024",
         "end": "6/2/2024",
      },
      "content": {
         "description": "Fall 2024 Application Now Open! Final Deadline: June 15",
      },
   },
   {
      "id": "brown-mph-2025-spring-start-early",
      "type": "start-date-custom",
      "include": [
         "/mph/"
      ],
      "dates": {
         "start": "6/2/2024",
         "end": "7/16/2024",
      },
      "content": {
         "description": "Spring 2025 Application Now Open! Early Action Deadline: July 15",
      },
   },
   {
      "id": "brown-mph-2025-spring-start-priority",
      "type": "start-date-custom",
      "include": [
         "/mph/",
         "/biostats"
      ],
      "dates": {
         "start": "7/16/2024",
         "end": "9/16/2024",
      },
      "content": {
         "description": "Spring 2025 Application Now Open! Priority Action Deadline: September 15",
      },
   },
   {
      "id": "brown-mph-2025-spring-start-final",
      "type": "start-date-custom",
      "include": [
         "/mph/",
         "/biostats"
      ],
      "dates": {
         "start": "9/16/2024",
         "end": "10/16/2024",
      },
      "content": {
         "description": "Spring 2025 Application Now Open! Early Action Deadline: July 15",
      },
   },
   {
      "id": "brown-bio-2025-spring-start-early",
      "type": "start-date-custom",
      "include": [
         "/biostats/"
      ],
      "dates": {
         "start": "2/1/2024",
         "end": "7/16/2024",
      },
      "content": {
         "description": "Spring 2025 Application Now Open! Early Action Deadline: July 15",
      },
   },
   {
      "id": "brown-bio-2025-fall-start-early",
      "type": "start-date-custom",
      "include": [
         "/biostats/"
      ],
      "dates": {
         "start": "10/16/2024",
         "end": "3/16/2025",
      },
      "content": {
         "description": "Fall 2025 Application Now Open! Early Action Deadline: March 15",
      },
   },
   {
      "id": "brown-bio-2025-fall-start-priority",
      "type": "start-date-custom",
      "include": [
         "/biostats/"
      ],
      "dates": {
         "start": "3/16/2025",
         "end": "5/2/2025",
      },
      "content": {
         "description": "Fall 2025 Application Now Open! Priority Deadline: May 1",
      },
   },
   {
      "id": "brown-bio-2025-fall-start-final",
      "type": "start-date-custom",
      "include": [
         "/biostats/"
      ],
      "dates": {
         "start": "5/2/2025",
         "end": "6/2/2025",
      },
      "content": {
         "description": "Fall 2025 Application Now Open! Final Deadline: June 1",
      },
   },
]